import { Form } from "@/helpers/Form";
import Pager from "@/helpers/Pager";
import { FilterModel } from "../services/DistrictsService";

export default {
    namespaced: true,
    state: {
        filter: Form.create<FilterModel>({
            cityPublicId: null,
            districtPublicId: null
        }),
        pager: new Pager(1, 100, 'name', 'ASC') as Pager
    }
};
