import { Form } from "@/helpers/Form";
import Pager from "@/helpers/Pager";
import { FilterModel } from "../services/OwnersService";

export default {
    namespaced: true,
    state: {
        filter: Form.create<FilterModel>({
            showNonActive: false,
            propertyPublicId: null,
            name: ''
        }),
        pager: new Pager(1, 100, 'GivenName', 'ASC')
    }
};
