import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';
import { FilterModel } from '../types';

export default {
    namespaced: true,
    state: {
        pager: new Pager(1, 100, 'PropertyName', 'ASC'),
        filter: Form.create<FilterModel>({
            propertyPublicId: null,
            assignedUserPublicId: null,
            citiesIds: [],
            districtsIds: [],
            statuses: [],
            departments: [],
            checkInOutToday: [],
            hasTaskToday: false,
            propertyWithoutTask: false,
            tagsIds: null,
            isAnd: false
        }),
        scrollPosition: 0
    }
};
