import { axios } from '@/plugins/axios';
import { DateTime } from 'luxon';
import { Resource, Statement } from '@/helpers/Interfaces';

/**
 * NotificationsService
 */
export default class NotificationsService
{
    /**
     * @returns Promise<ChannelType[]>
     */
    public static async getChannels(): Promise<ChannelType[]>
    {
        return (await axios.get<ChannelType[]>('admin/notifications/channels')).data;
    }

    /**
     * @returns Promise<NotificationType[]>
     */
    public static async getNotifications(): Promise<NotificationType[]>
    {
        return (await axios.get<NotificationType[]>('admin/notifications/types')).data;
    }

    /**
     * @returns Promise<UserNotiticationsFormModel>
     */
    public static async getUserSettings(): Promise<UserNotiticationsFormModel>
    {
        return (await axios.get<UserNotiticationsFormModel>('admin/notifications/settings')).data;
    }

    /**
     * @param model UserNotiticationsFormModel
     *
     * @returns Promise<Statement>
     */
    public static async saveUserSettings(model: UserNotiticationsFormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/notifications/settings`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<NotificationsOptions>>
     */
    public static async getGlobalSettings(): Promise<Resource<NotificationsOptions>>
    {
        return (await axios.get<Resource<NotificationsOptions>>('admin/notifications/globalsettings')).data;
    }

    /**
      * @param model NotificationsOptions
      *
      * @returns Promise<Statement>
      */
    public static async saveGlobalSettings(model: NotificationsOptions): Promise<Statement>
    {
        return (await axios.put<Statement>('admin/notifications/globalsettings', model)).data;
    }

    /**
      * @param userId number
      *
      * @returns Promise<Statement>
      */
    public static async sendTestNotification(userId: number): Promise<Statement>
    {
        return (await axios.get<Statement>(`admin/oneSignal/testNotification/${userId}`)).data;
    }

    /**
      *
      * @returns Promise<Statement>
      */
    public static async markAllNotificationsAsRead(): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/renters/notifications/markAsRead`)).data;
    }

    /**
      * @param publicId string
      *
      * @returns Promise<any>
      */
    public static async markChatAsRead(publicId: string): Promise<any>
    {
        return (await axios.put<any>(`admin/renters/notifications/markAsRead/chat/${publicId}`)).data;
    }
}

export interface ChannelType
{
    value: string;
    name: string;
    richText: boolean;
    required: boolean;
    enabled: boolean;
}

export interface NotificationType
{
    value: string;
    name: string;
    module: string;
    description: string;
    defaultSubject: string;
    defaultMessage: string;
    enabled: boolean;
    required: boolean;
    channels: string[];
    keys: KeyModel[];
}

export interface KeyModel
{
    key: string;
    description: string;
}

export interface UserNotiticationsFormModel
{
    channels: string[];
    notifications: NotiticationSettings[];
}

export interface NotiticationSettings
{
    channel: string;
    value: string;
}

export interface NotificationsOptions
{
    enabled: boolean;
    channels: string[];
    footer: string;
    logo?: File;
    logoUrl: string;
}

export interface FilterModel
{
    dateFromUtc?: DateTime;
    dateDueUtc?: DateTime;
    isRead?: boolean;
    types?: string;
}

export interface MessageModel
{
    id: number;
    uid: string;
    dateCreatedUtc: DateTime;
    payload: any;
    isRead: boolean;
}

export const MARK_AS_READ_CHATS = 'MARK_AS_READ_CHATS';
