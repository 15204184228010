import { Form } from "@/helpers/Form";
import Pager from "@/helpers/Pager";
import { FilterModel } from "../services/ReservationsService";

export default {
    namespaced: true,
    state: {
        filter: Form.create<FilterModel>({
            today: false,
            tomorrow: false,
            arrival: false,
            departure: false,
            custom: null,
            status: [],
            localizator: null,
            citiesIds: [],
            districtsIds: [],
            propertiesIds: [],
            webSource: null
        }),
        pager: new Pager(1, 100, 'ClientName', 'ASC')
    }
};
