import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';
import { FilterModel } from '../services/PropertiesService';

export default {
    namespaced: true,
    state: {
        filter: Form.create<FilterModel>({
            search: '',
            activeStatus: "Active",
            citiesIds: [],
            districtsIds: [],
            ownerName: '',
            guestyId: null,
            hasNoDistrict: false
        }),
        pager: new Pager(1, 100, 'PropertyName', 'ASC'),
        activityPager: new Pager(1, 100, 'DueDate', 'ASC'),
        notesPager: new Pager(1, 100, 'Content', 'ASC'),
        stocktakesPager: new Pager(1, 100, 'dateCreatedUtc', 'ASC'),
        savedScrollPosition: 0
    }
};
