/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import UsersService from '@/modules/core/users/services/UsersService';
import EmptyLayout from '@/views/layouts/EmptyLayout.vue';
import { RouteParams } from 'vue-router';
import CheckSuperUserPermission from '@/modules/renter/common/helpers/featureFlags';

const routeException = async (): Promise<RouteParams> =>
{
    try
    {
        const departments = await UsersService.getDepartment();

        const containsOtherThan = (departmentsOptions: string[]): boolean => departments.some(departmentItem => !departmentsOptions.includes(departmentItem));

        if (departments.length && !containsOtherThan(['HumanResources']))
        {
            return { name: 'core-users' };
        }
    }
    catch (ex)
    {
        return { name: 'core-account-profile' };
    }
};

export default [
    {
        path: 'app',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // Dashboard
            {
                path: 'dashboard',
                name: 'renter-dashboard',
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/dashboard/views/Dashboard.vue'),
                beforeEnter: () => routeException()
            },
            {
                path: 'dashboard/map',
                name: 'renter-dashboard-map',
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/dashboard/views/MapView.vue'),
                beforeEnter: () => routeException()
            },
            // Kalendarz
            {
                path: 'calendar',
                name: 'renter-calendar',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/calendar/views/MainView.vue')
            },
            {
                path: 'calendar/employee',
                name: 'renter-calendar-employee',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/calendar/views/MainView.vue')
            },
            // Nieruchomości
            {
                path: 'properties',
                name: 'renter-properties',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/properties/views/PropertiesList.vue')
            },
            {
                path: 'properties/info',
                name: 'renter-properties-info',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/properties/views/PropertiesList.vue')
            },
            {
                path: 'properties/info/:publicId',
                name: 'renter-properties-info-item',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/properties/views/PropertyInfo.vue')
            },
            {
                path: 'properties/add',
                name: 'renter-properties-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/properties/views/PropertyForm.vue'),
                beforeEnter: () => CheckSuperUserPermission()
            },
            {
                path: 'properties/:publicId',
                name: 'renter-properties-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/properties/views/PropertyDetails.vue')
            },
            // Firmy
            {
                path: 'companies',
                name: 'renter-companies',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "core" */ '@/modules/renter/companies/views/CompaniesList.vue')
            },
            {
                path: 'companies/add',
                name: 'renter-companies-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "core" */ '@/modules/renter/companies/views/CompanyForm.vue')
            },
            {
                path: 'companies/:publicId',
                name: 'renter-companies-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "core" */ '@/modules/renter/companies/views/CompanyForm.vue')
            },
            // Zadania
            {
                path: 'tasks',
                name: 'renter-tasks',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/tasks/views/TasksList.vue')
            },
            {
                path: 'tasks/expired',
                name: 'renter-tasks-expired',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/tasks/views/TasksList.vue')
            },
            {
                path: 'tasks/cleaning',
                name: 'renter-tasks-cleaning',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/tasks/views/TasksList.vue')
            },
            {
                path: 'tasks/inspection',
                name: 'renter-tasks-inspection',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/tasks/views/TasksList.vue')
            },
            {
                path: 'tasks/maintenance',
                name: 'renter-tasks-maintenance',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/tasks/views/TasksList.vue')
            },
            {
                path: 'tasks/add',
                name: 'renter-tasks-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/tasks/views/TaskForm.vue')
            },
            {
                path: 'tasks/deleted',
                name: 'renter-tasks-deleted',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/tasks/views/TasksList.vue')
            },
            {
                path: 'tasks/:publicId',
                name: 'renter-tasks-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/tasks/views/TaskForm.vue')
            },
            // Zaopatrzenie
            {
                path: 'supplies',
                name: 'renter-supplies',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/supplies/views/SuppliesList.vue')
            },
            // Rezerwacje
            {
                path: 'reservations',
                name: 'renter-reservations',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/reservations/views/ReservationsList.vue')
            },
            {
                path: 'reservations/:publicId',
                name: 'renter-reservations-details',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/reservations/views/ReservationDetails.vue')
            },
            {
                path: 'reservations/synchronization',
                name: 'renter-reservations-sync-job',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/reservations/views/ReservationsSync.vue'),
                beforeEnter: () => CheckSuperUserPermission()
            },
            // Checklisty
            {
                path: 'checklists/add',
                name: 'renter-checklists-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/checklists/views/ChecklistsForm.vue')
            },
            {
                path: 'checklists/:publicId',
                name: 'renter-checklists-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/checklists/views/ChecklistsForm.vue')
            },
            {
                path: 'checklists',
                name: 'renter-checklists',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/checklists/views/ChecklistsList.vue')
            },
            // Regiony
            {
                path: 'regions',
                name: 'renter-regions',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/regions/views/RegionsList.vue')
            },
            {
                path: 'regions/add',
                name: 'renter-regions-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/regions/views/RegionForm.vue')
            },
            {
                path: 'regions/:publicId',
                name: 'renter-regions-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/regions/views/RegionForm.vue')
            },
            // Miasta
            {
                path: 'cities',
                name: 'renter-cities',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/cities/views/CitiesList.vue')
            },
            {
                path: 'cities/add',
                name: 'renter-cities-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/cities/views/CityForm.vue'),
                beforeEnter: () => CheckSuperUserPermission()
            },
            {
                path: 'cities/:publicId',
                name: 'renter-cities-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/cities/views/CityForm.vue')
            },
            // Ustawienia globalne
            {
                path: 'settings',
                name: 'renter-global-settings',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/settings/views/GlobalSettingsForm.vue')
            },
            {
                path: 'tags',
                name: 'renter-tags',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/tags/views/TagsList.vue')
            },
            {
                path: 'tasks_tags',
                name: 'renter-tasks-tags',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/tasksTags/views/TasksTagsList.vue')
            },
            // Właściciele
            {
                path: 'owners',
                name: 'renter-owners',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/owners/views/OwnersList.vue')
            },
            {
                path: 'owners/add',
                name: 'renter-owners-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/owners/views/OwnerForm.vue')
            },
            {
                path: 'owners/:publicId',
                name: 'renter-owners-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/owners/views/OwnerForm.vue')
            },
            {
                path: 'stocktakes',
                name: 'renter-stocktakes',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/stocktakes/views/StocktakesList.vue')
            },
            {
                path: 'stocktakes/planned',
                name: 'renter-stocktakes-planned',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/stocktakes/views/StocktakesList.vue')
            },
            {
                path: 'stocktakes/add',
                name: 'renter-stocktakes-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/stocktakes/views/StocktakeForm.vue')
            },
            {
                path: 'stocktakes/:publicId',
                name: 'renter-stocktakes-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/stocktakes/views/StocktakeForm.vue')
            },
            {
                path: 'stocktakes/:publicId/:propertyId/addnote',
                name: 'renter-stocktakes-add-note',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/notes/views/NotesForm.vue')
            },
            {
                path: 'stocktakes/:publicId/editnote/:notePublicId',
                name: 'renter-stocktakes-edit-note',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/notes/views/NotesForm.vue')
            },
            // Notatki koordynatorów
            {
                path: 'notes',
                name: 'renter-notes',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/notes/views/NotesList.vue')
            },
            {
                path: 'notes/add',
                name: 'renter-notes-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/notes/views/NotesForm.vue')
            },
            {
                path: 'notes/:publicId',
                name: 'renter-notes-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/notes/views/NotesForm.vue')
            },
            // Obsługa zadań
            {
                path: 'tasks/management',
                name: 'renter-tasks-management',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/tasks_management/views/ListMainView.vue')
            },
            {
                path: 'tasks/management/:publicId',
                name: 'renter-tasks-management-run',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/tasks_management/views/TaskMainView.vue')
            },
            {
                path: 'tasks/management/:publicId/:propertyId/addnote',
                name: 'renter-tasks-management-add-note',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/notes/views/NotesForm.vue')
            },
            // Zadania mapa
            {
                path: 'tasks/myMap',
                name: 'renter-tasks-map',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/my_map/views/MyMap.vue')
            },
            // TRASY DLA KIEROWCÓW
            {
                path: 'drivers_routes/map',
                name: 'renter-drivers-routes-map',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/drivers_routes/views/MapView.vue')
            },
            {
                path: 'drivers_routes/list',
                name: 'renter-drivers-routes-list',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/drivers_routes/views/RoutesList.vue')
            },
            {
                path: 'drivers_routes/management/',
                name: 'renter-drivers-routes-management',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/drivers_routes/views/RouteManagementList.vue')
            },
            {
                path: 'drivers_routes/management/:publicId',
                name: 'renter-drivers-routes-management-route',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/drivers_routes/views/RouteManagementForm.vue')
            },
            {
                path: 'drivers_routes/archived',
                name: 'renter-drivers-routes-archived',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/drivers_routes/views/ArchivedRoutesList.vue')
            },
            {
                path: 'drivers_routes/commissions',
                name: 'renter-drivers-routes-commissions',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/drivers_routes/views/CommissionsList.vue')
            },
            {
                path: 'drivers_routes/commissions/:publicId',
                name: 'renter-drivers-routes-commission',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/drivers_routes/views/CommissionForm.vue')
            },
            // RAPORTY
            {
                path: 'cost_reports',
                name: 'renter-cost-reports',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/cost_reports/views/CostReportsList.vue')
            },
            {
                path: 'cost_reports/add',
                name: 'renter-cost-reports-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/cost_reports/views/CostReportsForm.vue')
            },
            {
                path: 'cost_reports/:publicId',
                name: 'renter-cost-reports-management',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/cost_reports/views/CostReportsManagement.vue')
            },
            {
                path: 'cost_reports/back_office',
                name: 'renter-cost-monthly-report',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/cost_reports/views/CostMonthlyReportView.vue')
            },
            //STATYSTYKI KOSZTÓW
            {
                path: 'cost_statistics/employees',
                name: 'renter-costs-statistics-employees',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/cost_statistics/views/MainView.vue')
            },
            {
                path: 'cost_statistics/requests',
                name: 'renter-costs-statistics-requests',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/cost_statistics/views/MainView.vue')
            },
            {
                path: 'cost_statistics/properties',
                name: 'renter-costs-statistics-properties',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/cost_statistics/views/MainView.vue')
            },
            // WYPŁATY
            {
                path: 'salaries/timesheet',
                name: 'renter-salaries-timesheet',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/salaries/views/SalariesMainView.vue')
            },
            {
                path: 'salaries/absences',
                name: 'renter-salaries-absences',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/salaries/views/SalariesMainView.vue')
            },
            {
                path: 'salaries/hk',
                name: 'renter-salaries-hk',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/salaries/views/SalariesMainView.vue')
            },
            {
                path: 'salaries/maintenance',
                name: 'renter-salaries-maintenance',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/salaries/views/SalariesMainView.vue')
            },
            {
                path: 'salaries/coordinator',
                name: 'renter-salaries-coordinator',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/salaries/views/SalariesMainView.vue')
            },
            {
                path: 'salaries/driver',
                name: 'renter-salaries-driver',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/salaries/views/SalariesMainView.vue')
            },
            {
                path: 'salaries/employees',
                name: 'renter-salaries-employees',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/salaries/views/SalariesMainView.vue')
            },
            {
                path: 'salaries/employees/:publicId',
                name: 'renter-salaries-employees-details',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/salaries/views/EmployeeDetailsView.vue')
            },
            {
                path: 'salaries/statistics',
                name: 'renter-salaries-statistics',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/salaries/views/SalariesMainView.vue')
            },
            // CHAT
            {
                path: 'chat/room',
                name: 'chat-room-list',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/chat/views/RoomsList.vue')
            },
            {
                path: 'chat/room/:id/settings',
                name: 'chat-room-settings',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/chat/views/Room.vue')
            },
            {
                path: 'chat/myrooms',
                name: 'chat-myrooms',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/chat/views/MyRoomsList.vue')
            },
            {
                path: 'chat/myrooms/:publicId',
                name: 'chat-myrooms-chat',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/chat/views/MyRoomsList.vue')
            },
            {
                path: 'chat/addChat/:taskPublicId',
                name: 'chat-add-from-task',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/chat/views/AddRoom.vue')
            },
            {
                path: 'chat/addChat/:routePublicId',
                name: 'chat-add-from-route',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/chat/views/AddRoom.vue')
            },
            {
                path: 'chat/addChat/:reservationPublicId',
                name: 'chat-add-from-reservation',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/chat/views/AddRoom.vue')
            },
            {
                path: 'chat/addChat/',
                name: 'chat-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/chat/views/AddRoom.vue')
            },
            //DANE WYDZIAŁÓW
            {
                path: 'departments',
                name: 'renter-departments',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/departments/views/DepartmentsList.vue')
            },
            //DZIELNICE MIAST
            {
                path: 'districts',
                name: 'renter-districts',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/districts/views/DistrictsList.vue')
            },
            {
                path: 'districts/add',
                name: 'renter-districts-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/districts/views/DistrictForm.vue')
            },
            {
                path: 'districts/:publicId',
                name: 'renter-districts-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/districts/views/DistrictForm.vue')
            },
            //NIESTANDARDOWE NIERUCHOMOŚCI
            {
                path: 'custom_properties',
                name: 'renter-custom-properties',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/custom_properties/views/CustomPropertiesList.vue')
            },
            {
                path: 'custom_properties/add',
                name: 'renter-custom-properties-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/custom_properties/views/CustomPropertiesForm.vue')
            },
            {
                path: 'custom_properties/:publicId',
                name: 'renter-custom-properties-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/custom_properties/views/CustomPropertiesForm.vue')
            },
            {
                path: 'warehouse_orders',
                name: 'renter-warehouse-orders',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "renter" */ '@/modules/renter/warehouse/views/WarehouseOrders.vue')
            },
        ]
    }
];
