import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';

export default {
    namespaced: true,
    state: {
        filter: Form.create({
            userBeingReplaced: null,
            replacingUser: null,
            year: new Date().getFullYear().toString(),
            month: (new Date().getMonth() + 1).toString()
        }),
        pager: new Pager(1, 100, 'DateBeginUtc', 'DESC')
    }
};
