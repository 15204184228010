import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';

export default {
    namespaced: true,
    state: {
        filter: Form.create({
            entityType: '',
            searchTerm: '',
            dateFromUtc: null,
            dateDueUtc: null
        }),
        pager: new Pager(1, 100, 'DateDeletedUtc', 'DESC')
    }
};
